import React from "react";
import { LogoutRoute } from "../../../context/AuthContext";
const ApplicationDetailView = React.lazy(
  () => import("../../../hooks/loan_applications/ApplicationDetailView"),
);
const GenericViewer = React.lazy(() => import("../../layouts/GenericViewer"));
const InventoryDetailView = React.lazy(
  () => import("../../../hooks/inventory/InventoryDetailView"),
);
const AdhocReports = React.lazy(
  () => import("../../pages/reports/AdhocReports"),
);
const BalanceSheetReports = React.lazy(
  () => import("../../pages/reports/BalanceSheetReports"),
);
const TransactionsHistoryTable = React.lazy(
  () => import("../../pages/reports/TransactionsHistoryTable"),
);
const AgingReports = React.lazy(
  () => import("../../pages/reports/AgingReports"),
);
const SettingsPage = React.lazy(() => import("../settings/SettingsPage"));
const DashboardPage = React.lazy(() => import("./DashboardPage"));
const GenericForm = React.lazy(() => import("../../layouts/GenericForm"));
const GenericRegister = React.lazy(
  () => import("../../layouts/GenericRegister"),
);

const DashboardRoutes = [
  {
    path: "/",
    component: <DashboardPage />,
    exact: true,
  },
  {
    path: "/register/:id",
    component: <GenericRegister />,
    exact: true,
  },
  {
    path: "/register/:id/:status",
    component: <GenericRegister />,
    exact: true,
  },
  {
    path: "/:source_id/create",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/:source_id/drafts/:draft_id",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/:source_id/update/:id",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/loan_application/view/:id",
    component: <ApplicationDetailView />,
    exact: true,
  },
  {
    path: "/inventory/view/:id",
    component: <InventoryDetailView />,
    exact: true,
  },
  {
    path: "/:source_id/view/:id",
    component: <GenericViewer />,
    exact: true,
  },
  {
    path: "/settings",
    component: <SettingsPage />,
    exact: true,
  },

  // Reports
  {
    path: `/reports/adhoc`,
    component: <AdhocReports />,
    exact: true,
  },
  {
    path: `/reports/balance-sheet`,
    component: <BalanceSheetReports />,
    exact: true,
  },
  {
    path: `/reports/debtors-ageing-report`,
    component: <AgingReports />,
    exact: true,
  },

  // Transactions History
  {
    path: `/transactions/:chart_id`,
    component: <TransactionsHistoryTable />,
    exact: true,
  },

  // Logout
  {
    path: "/logout",
    component: <LogoutRoute />,
    exact: true,
  },
];

export default DashboardRoutes;
